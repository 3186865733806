import React from 'react'
import { NavLink } from 'react-router-dom'
import Parallax from "../components/Contains/Parallax.js";
import RaisedBody from "../components/Contains/RaisedBody.js";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const NotFound = () => {
    return (
        <main>
            <Parallax>
                <Container>
                    <h1>Could not find page.</h1>
                </Container>
            </Parallax>
            <RaisedBody>
                <Container>
                    <Box mb={3}  justifyContent="center" display="flex" justifyContent="center" >
                        <h4>We could not find the page you were looking for.</h4> 
                    </Box>
                    <Box mb={0}  justifyContent="center" display="flex" justifyContent="center" >
                        <Button href="https://dashboard.jected.com/"  variant="contained" color="primary">Goto dashboard</Button>
                    </Box>
                    <Box mb={0}  justifyContent="center" display="flex" justifyContent="center" >
                        <h4> Or </h4>
                    </Box>
                    <Box mb={3}  justifyContent="center" display="flex" justifyContent="center" >
                        <Button href="/"  variant="contained" color="primary">Goto home</Button>
                    </Box>
                    <Box mt={3} p={5} whiteSpace="normal"/>
                </Container>
            </RaisedBody>
        </main>
    )
}

export default NotFound
