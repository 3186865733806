import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "../../assets/jss/components/parallaxStyle.js";

const useStyles = makeStyles(styles);

export default function Parallax(props)
{
  const {children} = props;
  const classes = useStyles();
  return (
      <div className={classes.parallax} >
        {children}
      </div>
  );
}

Parallax.propTypes = {
  children: PropTypes.node,
};
