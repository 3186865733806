const RaisedBody = {
    main: {
        margin: "-50px 30px 0px",
        boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
        borderRadius: "6px",
        zIndex: "3",
        position: "relative",
        background: "#FFFFFF",
        paddingTop:"20px",
        paddingBottom:"0",
        minHeight: "50px;"

    },
    TitleOnly: {
      height: "380px"
    }
  };
  
  export default RaisedBody;
  