import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import { List, ListItem } from "@material-ui/core";
import Link from '@material-ui/core/Link';


import styles from "../../assets/jss/components/FooterStyle.js";

const useStyles = makeStyles(styles);

const Footer = (props) => {

    const {user} = props
    const classes = useStyles();

    //var user = firebase.auth().currentUser;

    return (
        <footer className={classes.footer}>
            <Container>
                <div className={classes.left}>
                <List className={classes.list}>
                    <ListItem className={classes.inlineBlock}>
                    
                    <Link href="/"
                        className={classes.block}
                    >
                        Home
                    </Link>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                    <Link href="/contact"
                        className={classes.block}
                    >
                        Contact us
                    </Link>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                    <Link href="/terms"
                        className={classes.block}
                    >
                        Terms and conditions
                    </Link>
                    </ListItem>
                </List>
                </div>
                <div className={classes.right}>&copy; {1900 + new Date().getYear()}, made by {" "}
                <a
                    href="https://spried.com"
                    target="_blank"
                    className={classes.a}
                >
                    SPRIED
                </a>
                </div>

            </Container>
        </footer>
    );
    /*
    return (
        <footer className={footerClasses}>
            <div className={classes.container}>
                <div className={classes.left}>
                <List className={classes.list}>
                    <ListItem className={classes.inlineBlock}>
                    <a
                        href="https://www.creative-tim.com/?ref=mkr-footer"
                        className={classes.block}
                        target="_blank"
                    >
                        Creative Tim
                    </a>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                    <a
                        href="https://www.creative-tim.com/presentation?ref=mkr-footer"
                        className={classes.block}
                        target="_blank"
                    >
                        About us
                    </a>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                    <a
                        href="http://blog.creative-tim.com/?ref=mkr-footer"
                        className={classes.block}
                        target="_blank"
                    >
                        Blog
                    </a>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                    <a
                        href="https://www.creative-tim.com/license?ref=mkr-footer"
                        className={classes.block}
                        target="_blank"
                    >
                        Licenses
                    </a>
                    </ListItem>
                </List>
                </div>
                <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , made with{" "}
                <Favorite className={classes.icon} /> by{" "}
                <a
                    href="https://www.creative-tim.com?ref=mkr-footer"
                    className={aClasses}
                    target="_blank"
                >
                    Creative Tim
                </a>{" "}
                for a better web.
                </div>
            </div>
        </footer>
    )*/
}

export default (Footer)