/*eslint-disable*/
import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "@material-ui/core/Link";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
//import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
//import Button from "components/CustomButtons/Button.js";
import Button from "@material-ui/core/Button";


import styles from "../../assets/jss/mainStyle";
const useStyles = makeStyles(styles);

const HeaderSignin = (props) => {

    const classes = useStyles();
    //const {user} = props;
    var {fullWidth} = props;

    if(!fullWidth)
    fullWidth = false;


    //if(user != null)
        return (<Button id="LoginIdButtonHeader" fullWidth={fullWidth} href="https://basic.jected.com/"  variant="contained" className={classes.Button}>dashboard</Button>)

    //return (<Button fullWidth={fullWidth}  href="https://dashboard.jected.com/"  variant="contained" color="primary">Login</Button>)
}
/*
const mapStateToProps = (state) => {
    return {
        //user:firebase.auth().currentUser
    }
}*/

export default /*connect(mapStateToProps)*/(HeaderSignin)