import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';

// core components
import styles from "../../assets/jss/components/RaisedBodyStyle.js";

const useStyles = makeStyles(styles);

export default function RaisedBody(props)
{
  const {children} = props;
  const classes = useStyles();
  return (
    <div className={classes.main}>
            {children}
    </div>
  );
  /*return (
    <div className={classes.main}>
        <div className={classes.container}>
            <div className={classesproductStyle.section}>
                <div className={classes.RaisedBody} >
                    {children}
                </div>
            </div>
        </div>
    </div>
  );*/
}
/*
Parallax.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.string,
  image: PropTypes.string,
  small: PropTypes.bool
};*/
