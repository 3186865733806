import React from 'react'

import Box from "@material-ui/core/Box";



const Header = () => {

    return (
        <Box >
          <h3 >Loading...</h3>
        </Box>
    )
}


export default (Header)