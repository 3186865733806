import mainStyle from "../mainStyle.js";

const featuresListStyle = {
    mainStyle,
    PriceContainer:{
      position:"relative"
    },
    PriceOverLayBG:{
      zIndex:"modal",
      position:"absolute",
      top:"0px",
      left:"0px",
      right:"0px",
      bottom:"0px",
      padding:"3rem",
      background:"#0000006e"
    },
    PriceOverLay:{
      padding:"1rem",
      background:"#fff",
      textAlign:"center",
      boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.3), 0 6px 30px 5px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.4)",
      borderRadius: "6px",
    },
    DesignedContainer:{
      background: '#fff',
      clipPath: "polygon(0 0, 100% 0, 100% 96%, 0 100%);",
      '&:nth-child(even)': {
          background: '#eee',
      },

    },
    ContainerArrowSeperator:{
      textAlign: "center",
      fontSize: "40px",
      color: "#4286ea",
      //margin:"auto",
      marginBottom:"-22px",
      position:"relative"
    },
    resourceButtons: {
      background: "#4286ea",
      color:"#fff",
      width: "100%",
      height: "52px",
      padding:"10px",
      '&:hover': {
        color: '#333',
      },
      PriceButtons:{
        background: "#4286ea",
        color:"#fff",
        width: "100%",
        height: "52px",
        padding:"10px",
        '&:hover': {
          color: '#333',
        }
      },
      DesignedHeading:{
        align:"center",
        alignItems:"center",
        justify:"center"
      }
    }
  }

  export default featuresListStyle;
  