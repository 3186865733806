const parallaxStyle = {
    parallax: {
      //height: "60vh",
      //maxHeight: "1000px",
      //minHeight: "200px",
      overflow: "hidden",
      position: "relative",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      margin: "0",
      padding: "0",
      border: "0",
      //display: "flex",
      alignItems: "center",
      background: "#4286ea",
      padding: "100px 30px 70px",
      color:"#fff",
    },
    TitleOnly: {
      height: "200px"
    }
  };
  
  export default parallaxStyle;
  