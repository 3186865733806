//import { container, primaryColor } from "assets/jss/material-kit-react.js";

const mainStyle = {
    secondaryContainer: {
      //background: "#eee",
    },

    primaryContainer: {
      //background: "#fff",
      },
      Button: {
        background: "#4286ea",
        color:"#fff",
        height: "52px",
        padding:"10px",
        '&:hover': {
          color: '#aaa',
          background: "#4286ea",
        }
      },
      WhiteButton: {
        background: "#fff",
        color:"#4286ea",
        height: "52px",
        padding:"10px",
        '&:hover': {
          color: '#4286ea',
          background: "#aaa",
        }
      }
  };
  export default mainStyle;
  