import React, { useState, useEffect } from 'react'
//import firebase from 'firebase';
import Button from '@material-ui/core/Button';
import Parallax from "../components/Contains/Parallax.js";
import RaisedBody from "../components/Contains/RaisedBody.js";
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";


import Link from '@material-ui/core/Link';
import { NavLink } from 'react-router-dom'
import * as Icons from '@material-ui/icons'

import {apiURL} from "../api/Access.js";
import Loading from "../components/Loading.js";
import styles from "../assets/jss/views/homeStyle.js";
const useStyles = makeStyles(styles);


const Resource = (props) => {

    const {Resources} = props;
    const classes = useStyles();
    const [resourcesStatus, setResourcesStatus] = useState({loading:true});
    const [resourcesData, setResourcesData] = useState(null);
    
  function getFirstNWords(n,text) {
    // Split the text into words using regular expression
    var words = text.split(/\s+/);
    
    // Return the first 100 words
    return words.slice(0, n).join(' ');
}


    useEffect(() => {
        document.title = `Jected Resources`;
        setResourcesStatus({loading:true});
        setResourcesData(null);


        fetch(apiURL+'resources').then(async (response)=>{

            console.log(response)
            try 
            {
                const jsonData = await response.json();
                if(jsonData.pages)
                {
                    setResourcesStatus({ error: true});
                    setResourcesData(jsonData.pages);
                }
                else
                {
                    setResourcesStatus({ error: true});
                }
            }
            catch (error) 
            {
                setResourcesStatus({ error: true});
            }
        })


      },[]);

    

        return (
            <main>
                <Parallax>
                    <Container>
                        <h1>Resources</h1>
                    </Container>
                </Parallax>
                <RaisedBody>
                    <Container>
                        
                    {resourcesStatus.loading ? <Loading/> : null}

                        <Grid container spacing={2}>

                            { resourcesData && resourcesData.map((item, index) => {
                                
                            const Icon = item.icon in Icons ? Icons[item.icon] : null
                                return (
                                    <Grid component={NavLink}  to={"/resource/"+item.id} item xs={12} sm={6} md={4} lg={3} spacing={5} className={classes.ResourcesList} >
                                        <div className={classes.ResourcesListIcon} >
                                            <Icon/>
                                        </div>

                                        <h4 gutterBottom>{item.name}</h4>

                                        <div>
                                            {getFirstNWords(50,item.excerpt)}
                                        </div>
                                        
                                        <Link component={NavLink}  to={"/resource/"+item.id} >
                                            More Details
                                        </Link>
                                    </Grid>
                                )
                            })} 
                            </Grid>

                            <br/>
                        <br/>
                        <br/>
                        <br/>
                    </Container>
                </RaisedBody>
            </main>
        )
}

export default (Resource)