import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button';
import classNames from "classnames";
import Grid from "@material-ui/core/Grid";
import Parallax from "../components/Contains/Parallax.js";
import RaisedBody from "../components/Contains/RaisedBody.js";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import { makeStyles } from "@material-ui/core/styles";
import Link from '@material-ui/core/Link';
import { NavLink } from 'react-router-dom'


import * as Icons from '@material-ui/icons'

import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';

//#4286ea
import styles from "../assets/jss/views/homeStyle.js";

import {apiURL} from "../api/Access.js";
import Loading from "../components/Loading.js";



const useStyles = makeStyles(styles);


const Home  = (props) => {

    const classes = useStyles();

    const [workflowStatus, setWorkflowStatus] = useState({loading:true});
    const [workflowData, setWorkflowData] = useState(null);
    const [resourcesStatus, setResourcesStatus] = useState({loading:true});
    const [resourcesData, setResourcesData] = useState(null);
    const [featuresStatus, setFeaturesStatus] = useState({loading:true});
    const [featuresData, setFeaturesData] = useState(null);


    
  function getFirstNWords(n,text) {
    // Split the text into words using regular expression
    var words = text.split(/\s+/);
    
    // Return the first 100 words
    return words.slice(0, n).join(' ');
}


    useEffect(() => {
        document.title = `Jected`;
        setWorkflowStatus({loading:true});
        setWorkflowData(null);
        setResourcesStatus({loading:true});
        setResourcesData(null);
        setFeaturesStatus({loading:true});
        setFeaturesData(null);

        fetch(apiURL+'workflow').then(async (response)=>{
            console.log(response)
            try 
            {
                const jsonData = await response.json();
                if(jsonData.pages)
                {
                    setWorkflowStatus({ error: true});
                    setWorkflowData(jsonData.pages);
                }
                else
                {
                    setWorkflowStatus({ error: true});
                }
            }
            catch (error) 
            {
                setWorkflowStatus({ error: true});
            }
        })

        fetch(apiURL+'resources').then(async (response)=>{

            console.log(response)
            try 
            {
                const jsonData = await response.json();
                if(jsonData.pages)
                {
                    setResourcesStatus({ error: true});
                    setResourcesData(jsonData.pages);
                }
                else
                {
                    setResourcesStatus({ error: true});
                }
            }
            catch (error) 
            {
                setResourcesStatus({ error: true});
            }
        })

        fetch(apiURL+'features').then(async (response)=>{

            console.log(response)
            try 
            {
                const jsonData = await response.json();
                if(jsonData.pages)
                {
                    setFeaturesStatus({ error: true});
                    setFeaturesData(jsonData.pages);
                }
                else
                {
                    setFeaturesStatus({ error: true});
                }
            }
            catch (error) 
            {
                setFeaturesStatus({ error: true});
            }
        })


      },[]);

    

    return (
        <main>
            <Parallax>
                <Container>
                    <h1 className={classes.Sitetitle}>Jected</h1>
                    <h2 className={classes.Subtitle}>Resource Planning</h2>
                </Container>
            </Parallax>

            <RaisedBody>

                <Container mt={4} className={classes.secondaryContainer}>
                    <h2 gutterBottom>Coming soon</h2>
                        {resourcesStatus.loading ? <Loading/> : null}
                    <Grid container spacing={2}>


                        { resourcesData && resourcesData.map((item,index) => {

                            const Icon = item.icon in Icons ? Icons[item.icon] : null

                            return (
                                <Grid component={NavLink}  to={"/resource/"+item.id} item xs={12} sm={6} md={4} lg={3} spacing={5} className={classes.ResourcesList} >
                                    <div className={classes.ResourcesListIcon} >
                                        <Icon/>
                                    </div>

                                    <h4 gutterBottom>{item.name}</h4>

                                    <div>
                                        {getFirstNWords(50,item.excerpt)}...
                                    </div>
                                    
                                    <Link component={NavLink}  to={"/resource/"+item.id} >
                                        More Details
                                    </Link>
                                </Grid>
                            )
                        })} 
                    </Grid>
                    <br/>
                    <br/>
                </Container>








                <Box mt={2} className={classes.secondaryContainer}>
                    <Box>
                        <Container>
                            <h2 classes={classes.DesignedHeading} >
                                Made for your workflow
                            </h2>
                        </Container>
                    </Box>




                    {workflowStatus.loading ? <Box><Container><Box><Loading/></Box></Container></Box> : null}

                    { workflowData && workflowData.map((item,index) => {

                    //const Icon = item.icon in Icons ? Icons[item.icon] : null

                    return (

                        <Box /*component={NavLink}  to={"/resource/"+item.id}*/ className={classes.DesignedContainer}>
                            <Container>
                                <h3 align="center" classes={classes.DesignedHeading} >{item.name}</h3>
                                <p>{item.excerpt}</p>
                            </Container>
                        </Box>
                    )
                    })} 

                    {/*}
                    <Box className={classes.DesignedContainer}>
                        <Container>
                            <h3 align="center" classes={classes.DesignedHeading} >Design</h3>
                            {/*<p>Jeacted will help you mangma</p>* /}
                        </Container>
                    </Box> 
                    <Box className={classes.DesignedContainer}>
                        <Container>
                            <h3 align="center" className={classes.DesignedHeading}>Develop</h3>
                            {/*<p>Jeacted will help you mangma</p>* /}
                        </Container>
                    </Box>
                    <Box className={classes.DesignedContainer}>
                        <div>
                        <Container>
                            <h3 align="center" className={classes.DesignedHeading}>Test</h3>
                            {/*<p>Jeacted will help you mangma</p>* /}
                        </Container>
                        </div>
                    </Box>
                    <Box className={classes.DesignedContainer}>
                        <Container>
                            <h3 align="center" className={classes.DesignedHeading}>Produce</h3>
                            {/*<p>Jeacted will help you mangma</p>* /}
                        </Container>
                    </Box>
                    <Box className={classes.DesignedContainer}>
                        <Container>
                            <h3 align="center" className={classes.DesignedHeading}>Service</h3>
                            {/*<p>Jeacted will help you mangma</p>* /}
                        </Container>
                    </Box>
                    */}
                </Box>


 {/*
                <Container mt={2} className={classes.secondaryContainer}>
                    <h2 gutterBottom>Join a Community Project</h2>

                    <div>No Community Projects in Jected, stay tuned.</div>

                    list Community Project 

                    <Button component={NavLink}  to="/community/" className={classes.Button} size="large"   to="">
                                And more…
                    </Button>
                </Container>*/}



                <Container mt={4} className={classes.secondaryContainer}>
                    <h2 gutterBottom>Features</h2>
                    <div>
                        {featuresStatus.loading ? <Loading/> : null}
                    </div>
                    <Grid container spacing={2}>
                        { featuresData && featuresData.map((item,index) => {
                            return (
                                <Grid item xs={12} sm={6} md={4} lg={3} spacing={3}>
                                    <Link component={NavLink} to={"/features/"+item.id} >
                                        {item.name}
                                    </Link>
                                </Grid>
                            )
                        })} 
                    </Grid>
                </Container>
                <br/>
                <br/>








{/*}
                <Box mt={4} className={classes.PriceContainer}>
                    <Container mb={3} className={classes.secondaryContainer}>
                        <h2 gutterBottom>Pricing</h2>

                        <Grid mt={1} container spacing={3}>
                            <Grid item xs={12} sm={12} md={3} spacing={3}>
                                <Box pb={1} className={classes.PriceTables}>
                                    <h3>Basic</h3>
                                    <hr/>
                                    <h4>All Features</h4>
                                    <h4>Any Community Projects</h4>
                                    <h4>Limited Teams</h4>
                                    <hr/>
                                    <h3>Free</h3><p>per user</p>
                                    <Button component={NavLink}  to="/plan/basic" className={classes.PriceButtons}>
                                        See More…
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}  spacing={3}>
                                <Box  pb={1} className={classes.PriceTables}>
                                    <h3>Professional</h3>
                                    <hr/>
                                    <h4>All Features</h4>
                                    <h4>Any Community Projects</h4>
                                    <h4>+1 Team Member</h4>
                                    <hr/>
                                    <h3>$5USD</h3><p> user/per month</p>
                                    <Button component={NavLink}  to="/plan/professional-month" className={classes.PriceButtons}>
                                        See More…
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}  spacing={3}>
                                <Box  pb={1} className={classes.PriceTables}>
                                    <h3>Professional</h3>
                                    <hr/>
                                    <h4>All Features</h4>
                                    <h4>Any Community Projects</h4>
                                    <h4>+1 Team Member</h4>
                                    <hr/>
                                    <h3>(25% off)</h3><p>$45USD user/per year</p>

                                    <Button component={NavLink}  to="/plan/professional-year" className={classes.PriceButtons}>
                                        See More…
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} spacing={3}>
                                <Box pb={1} className={classes.PriceTables}>
                                    <h3>Professional</h3>
                                    <hr/>
                                    <h4>All Features</h4>
                                    <h4>Any Community Projects</h4>
                                    <h4>All Team Member</h4>
                                    <hr/>
                                    <h3>Contact Us</h3>
                                    <p>A plan for your Business.</p>
                                    <Button component={NavLink}  to="/plan/enterprise"  className={classes.PriceButtons}>
                                        See More…
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>


                        <Box  className={classes.PriceOverLayBG}>
                            <Container  className={classes.PriceOverLay}>
                                <h3 >Jected is curretly free</h3>
                                <p>Since this site is only in Alpha Release, Jected is curretly free.</p>
                                <p>Please see Terms and conditions for more details.</p>
                                <Button href="https://dashboard.jected.com/"  variant="contained" className={classes.Button}>Goto dashboard</Button>
                            </Container>
                        </Box>
                    </Container>
                </Box>
                    */}



            </RaisedBody>
        </main>
    )
}





  
export default (Home)
