import React, { useState, useEffect } from 'react'
import Parallax from "../components/Contains/Parallax.js";
import RaisedBody from "../components/Contains/RaisedBody.js";
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import axios from 'axios'

//import { connect } from 'react-redux'


const Contact = () => {
    
    
    
  const [values, setValues] = useState({});
  const [device, setdevice] = useState({});
  const [{Loading,finished,error}, setForm] = useState({Loading:false, finished:false,error:false});


  
  const handleChange = (event) => {
    setValues({
        ...values,
        [event.target.name]: event.target.value
    });
};


const handleVerify=token => {
    console.log(token)
    setValues({
        ...values,
        token: token
    });
}


const handleTimeout = () => {
    console.log()
    if(!finished)
      setForm({Loading:false, finished:false, error:true});
};

const handleSubmit = (event) => { 
    event.preventDefault();
    console.log("submit")
    setForm({Loading:true, finished:false, error:false});
/*
    if(firebase.auth().currentUser)
    {
        firebase.auth().currentUser.getIdToken().then(function(idToken) {

            handleForm(idToken);

        }).catch(function(error) {
                setForm({Loading:false, finished:false, error:true});
        });
    }
    else
    {*/
        handleForm(null);
    //}
};

const handleForm = (idToken) =>{
    
    var MessageData = {};
    var HeaderData = {};

    MessageData.service = "spried";

    if(values.name)
        MessageData.name = values.name;

    if(values.token)
        MessageData.token = values.token;

    if(values.email)
        MessageData.email = values.email;
        
    if(values.message)
        MessageData.message = values.message;

    /*if(values.idToken)
    {
        MessageData.userIdToken = values.idToken;
        HeaderData = {"headers":{"Authorization": idToken}};
    }*/


    axios.post('https://api.spried.com/contact',MessageData).then(Result => {
        
        console.log(Result)
        if(Result.data.device)
            setdevice(Result.data.device);

        setForm({Loading:false, finished:true, error:false});
        
    }).catch(err => {
        //setError(err);
        setForm({Loading:false, finished:false, error:true});
    });  
}


if(finished)

return (
    <main>
            <Parallax>
                <Container>
                    <h1>Contact us</h1>
                </Container>
            </Parallax>
            <RaisedBody>
                        <Container>
                                                
                            <h3>Thank you</h3>
                            <p>Someone will contact you soon</p>
                            <br/>
                            <br/>
                            <br/>
                        </Container>
                    </RaisedBody>
                </main>
            )



        return (
<main>
        <Parallax>
            <Container>
                <h1>Contact us</h1>
            </Container>
        </Parallax>
        <RaisedBody>
                    <Container>
                                            
                        <h3>submit form to Contact</h3>

                        {error ? <Box style={{background:"#ffadad", padding:"5px", marginBottom:"10px"}}>Error — Try again later.</Box> : null}
                        {Loading ? <Box style={{background:"#add2ff", padding:"5px", marginBottom:"10px"}}>Loading </Box> : null}


                            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                <TextField  onChange={handleChange} fullWidth name="name"  label="Name" />
                                <br/><br/>
                                <TextField  onChange={handleChange} fullWidth name="email"   label="Email" />
                                <br/><br/>
                                <TextField  onChange={handleChange} fullWidth name="message"    label="Message" multiline rows={4}/>
                                <br/><br/>

                                <Button  type="submit"  variant="contained">Send</Button>
                                <br/><br/>
                            </form>

                    </Container>
                </RaisedBody>
            </main>
        )
    
}
export default Contact
