import React, { useState, useEffect } from 'react'
import Parallax from "../components/Contains/Parallax.js";
import RaisedBody from "../components/Contains/RaisedBody.js";
import Container from '@material-ui/core/Container';
import NotFound from './NotFound404'
import { useLocation } from 'react-router-dom'


import {apiURL} from "../api/Access.js";
import Loading from "../components/Loading.js";

const FeaturesDetails = (props) => {


    console.log(props.match.params)
    const [featuresStatus, setFeaturesStatus] = useState({loading:true});
    const [featuresData, setFeaturesData] = useState(null);


    
  function getFirstNWords(n,text) {
    // Split the text into words using regular expression
    var words = text.split(/\s+/);
    
    // Return the first 100 words
    return words.slice(0, n).join(' ');
}


    useEffect(() => {
        document.title = `Jected Features`;
        setFeaturesStatus({loading:true});
        setFeaturesData(null);

        if(!props.match || !props.match.params || !props.match.params.id)
        {
            setFeaturesStatus({error:true});
            return;
        }

        fetch(apiURL+'resources/'+props.match.params.id).then(async (response)=>{

            try 
            {
                const jsonData = await response.json();
                if(jsonData.page)
                {
                    setFeaturesData(jsonData.page);
                    setFeaturesStatus({});
                }
                else
                {
                    setFeaturesStatus({ error: true});
                }
            }
            catch (error) 
            {
                setFeaturesStatus({ error: true});
            }
        })


      },[]);

      if(featuresStatus.loading)
        return (<main>
            <Parallax>
                <Container>
                    <h1>Loading</h1>
                </Container>
            </Parallax>
        </main>)

      if(featuresStatus.error)
          return (<NotFound/>)

        console.log(featuresData)

    return (
        <main>
            <Parallax>
                <Container>
                    <h1>{featuresData.name}</h1>
                </Container>
            </Parallax>
            <RaisedBody>
                <Container>
                    {featuresData.content}
                </Container>
                <br/>
            </RaisedBody>
        </main>
    )
}

export default (FeaturesDetails)