/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "@material-ui/core/Link";

import { NavLink } from 'react-router-dom'
// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
//import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
//import Button from "components/CustomButtons/Button.js";
import Button from "@material-ui/core/Button";


import styles from "../../assets old/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Link component={NavLink}  to="/" className={classes.navLink}>
          Home
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link component={NavLink}  to="/resource" className={classes.navLink}>
          Resources 
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link component={NavLink}  to="/features" className={classes.navLink}>
          Features
        </Link>
      </ListItem>
      {/*<ListItem className={classes.listItem}>
        <Link component={NavLink}  to="/community" className={classes.navLink}>
          Community
        </Link>
  </ListItem>*/}
      <ListItem className={classes.listItem}>
        <Link component={NavLink}  to="/contact" className={classes.navLink}>
          Contact
        </Link>
      </ListItem>
    </List>
  );
}
