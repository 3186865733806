import React, { useState, useEffect } from 'react'
import Parallax from "../components/Contains/Parallax.js";
import RaisedBody from "../components/Contains/RaisedBody.js";
import Container from '@material-ui/core/Container';import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Link from '@material-ui/core/Link';

import { NavLink } from 'react-router-dom'


import * as Icons from '@material-ui/icons'
import styles from "../assets/jss/views/featuresListStyle.js";

import {apiURL} from "../api/Access.js";
import Loading from "../components/Loading.js";
const useStyles = makeStyles(styles);


const Features = (props) => {
    
    const classes = useStyles();

    const [featuresStatus, setFeaturesStatus] = useState({loading:true});
    const [featuresData, setFeaturesData] = useState(null);


    
  function getFirstNWords(n,text) {
    // Split the text into words using regular expression
    var words = text.split(/\s+/);
    
    // Return the first 100 words
    return words.slice(0, n).join(' ');
}


    useEffect(() => {
        document.title = `Jected Features`;
        setFeaturesStatus({loading:true});
        setFeaturesData(null);

        fetch(apiURL+'features').then(async (response)=>{

            console.log(response)
            try 
            {
                const jsonData = await response.json();
                if(jsonData.pages)
                {
                    setFeaturesStatus({ error: true});
                    setFeaturesData(jsonData.pages);
                }
                else
                {
                    setFeaturesStatus({ error: true});
                }
            }
            catch (error) 
            {
                setFeaturesStatus({ error: true});
            }
        })


      },[]);


        return (
            <main>
                <Parallax>
                    <Container>
                        <h1>Features</h1>
                    </Container>
                </Parallax>
                <RaisedBody>
                    <Container>

                        {featuresStatus.loading ? <Loading/> : null}

                        <Grid container spacing={2}>
                            { featuresData && featuresData.map((item,index) => {
                                return (
                                    <Grid item lg={12} spacing={3}>
                                        <Link component={NavLink} to={"/features/"+item.id} >
                                            <h3>{item.name}</h3>
                                        </Link>
                                        <p>{item.excerpt}</p>

                                        <Link component={NavLink}  to={"/features/"+item.id} >
                                        More Details
                                    </Link>
                                    </Grid>
                                )
                            })} 
                        </Grid>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </Container>
                </RaisedBody>
            </main>
        )
}

  
export default (Features)